import { Injectable } from '@angular/core';
import { Claim, State } from '@wam/shared';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { sortBy } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { GrantRequest } from './grant-request.model';
import { WithAuthService } from '@app/shared/services/with-auth.service';
import { RosteringService } from '@app/teacher-users/rostering-data.service';

@Injectable({
  providedIn: 'root',
})
export class GrantService extends WithAuthService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    public store: Store<State>,
    private rosteringService: RosteringService,
  ) {
    super(store);
  }

  loadClaims(app?: string): Observable<Claim[]> {
    return this.withUser().pipe(
      mergeMap(({ application }) =>
        this.apiGatewayService
          .get<Claim[]>(`grant/v2/apps/${app ?? application}/authorizations/claims?status=ACTIVE`)
          .pipe(map((claims: Claim[]) => sortBy(claims, ['resourceName']))),
      ),
    );
  }

  getClaim(authorization: string, application: string): Observable<Claim> {
    return this.apiGatewayService.get<Claim>(
      `grant/v2/apps/${application}/authorizations/${authorization}`,
    );
  }

  getAuthorizationApp(authorization: string): Observable<string> {
    return this.getClaim(authorization, 'waterford-upstart').pipe(
      map(() => 'waterford-upstart'),
      catchError(() => of('waterford-parent')),
    );
  }

  claim(authorization: string, app?: string, tags?: Claim['tags']): Observable<Claim> {
    return this.withUser().pipe(
      mergeMap(({ application }) =>
        this.apiGatewayService.post<Claim>(
          `grant/v2/apps/${app ?? application}/authorizations/${authorization}/claims`,
          {
            deviceKey: 'browser',
            tags,
          },
        ),
      ),
    );
  }

  removeChild(claimId: string): Observable<void> {
    return this.withUser().pipe(
      mergeMap(({ application }) =>
        this.apiGatewayService.delete<void>(
          `grant/v2/apps/${application}/authorizations/claims/${claimId}`,
        ),
      ),
    );
  }

  loadGeneratedClaims(classUUID: string, app: string): Observable<Claim[]> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      switchMap(({ organization }) => {
        return this.apiGatewayService.get<Claim[]>(
          `grant/v2/apps/${app}/orgs/${organization}/authorizations?grantor=${classUUID}&status=active`,
        );
      }),
      take(1),
    );
  }

  generate(claimRequests: GrantRequest[], grantor: string, app: string): Observable<Claim[]> {
    return combineLatest([
      this.rosteringService.getCurrentOrganization(),
      this.store.select(authenticationSelectors.getCurrentUser),
    ]).pipe(
      mergeMap(([{ name }, { organization, uuid }]) =>
        this.apiGatewayService.put<Claim[]>(
          `grant/v2/apps/${app}/orgs/${organization}/authorizations`,
          {
            grantor,
            user: uuid,
            resources: claimRequests,
            tags: { organizationName: name },
          },
        ),
      ),
      take(1),
    );
  }

  revoke(claims: Claim[], grantor: string, app: string) {
    return this.store.select(authenticationSelectors.getCurrentUser).pipe(
      mergeMap(({ organization }) =>
        forkJoin(
          claims.map((claim) =>
            this.apiGatewayService.patch(
              `grant/v2/apps/${app}/orgs/${organization}/authorizations/${claim.id}`,
              {
                grantor,
              },
            ),
          ),
        ),
      ),
      take(1),
    );
  }

  getUpstartClaims(studentId: string): Observable<Claim[]> {
    return this.store.select(authenticationSelectors.getCurrentUser).pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.get<Claim[]>(
          `grant/v2/apps/waterford-shared-upstart/orgs/${organization}/resources/${studentId}/claims?status=ACTIVE`,
        ),
      ),
      catchError((err) => {
        if (err.status === 404) {
          return of([]);
        } else {
          throw err;
        }
      }),
      take(1),
    );
  }
}
